import React, { useState, useEffect } from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import { navMenuClass } from "../../utils"

const Navbar = () => {
  const [activePage, setActivePage] = useState("index")

  useEffect(() => {
    const navbar = document.querySelector(".navbar")
    const menu = document.getElementById("nav-menu")

    document.addEventListener('scroll', () =>{
      if (window.scrollY > 10) {
        navbar.classList.add("fade-in")
        navbar.classList.remove("fade-out")
      } else {
        navbar.classList.add("fade-out")
        navbar.classList.remove("fade-in")
        menu.classList.remove("is-active")
      }
    });
  }, []);

  const toggleBodyScrolling = (menu) => {
    if (menu.classList.contains("is-active")) {
      document.body.classList.add("disable-scrolling")
      document.documentElement.classList.add("disable-scrolling")
    } else {
      document.body.classList.remove("disable-scrolling")
      document.documentElement.classList.remove("disable-scrolling")
    }
  }

  const closeMenu = () => {
    const menu = document.getElementById("nav-menu")
    const burger = document.getElementById("nav-burger")

    burger.classList.remove("is-active")
    menu.classList.remove("is-active")

    toggleBodyScrolling(menu);
  }

  const toggleMenu = () => {
    const menu = document.getElementById("nav-menu")
    const burger = document.getElementById("nav-burger")
    const navbar = document.querySelector(".navbar")

    burger.classList.toggle("is-active")
    menu.classList.toggle("is-active")

    if (window.scrollY === 0) {
      navbar.classList.toggle("reveal")
      navbar.classList.remove("fade-out")
    }

    toggleBodyScrolling(menu);
  }

  const changePage = (event) => {
    closeMenu()
    setActivePage(event.currentTarget.innerHTML.toLowerCase())
    document.querySelector(".navbar").classList.remove("reveal")
  }

  return (
    <React.Fragment>
      <nav
        className="navbar is-fixed-top is-justify-content-space-between"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="navbar-brand is-justify-content-center peanut-caramel-logo">
          <Link to="/" className="navbar-item" onClick={changePage}>
            <StaticImage
              alt="Peanut Caramel logo"
              src="../../images/peanut-caramel-logo.svg"
              placeholder="blurred"
              width={70}
            />
          </Link>

          <button
            id="nav-burger"
            className="navbar-burger"
            aria-label="menu"
            aria-expanded="false"
            onClick={toggleMenu}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </button>
        </div>

        <div id="nav-menu" className="navbar-menu">
          <div className="navbar-start is-size-5">
            <a
              href="https://grubdailygourmet.myshopify.com"
              target="_blank"
              rel="noreferrer"
              className="navbar-item"
            >
              Shop
            </a>

            <Link to="/stockists" className={navMenuClass("stockists", activePage)} onClick={changePage}>
              Stockists
            </Link>

            <Link to="/recipes" className={navMenuClass("recipes", activePage)} onClick={changePage}>
              Recipes
            </Link>

            <Link to="/about" className={navMenuClass("about", activePage)} onClick={changePage}>
              About
            </Link>

            <Link to="/contact" className={navMenuClass("contact", activePage)} onClick={changePage}>
              Contact
            </Link>
          </div>
        </div>
      </nav>
    </React.Fragment>
  )
}

export default Navbar
