import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const Footer = ({ activePage }) => {
  return (
    <footer className="footer has-dark-grey-background-color has-text-white">
      <div className="container">
        <div className="columns px-4">
          <div className="column is-flex is-flex-direction-column">
            <div className="container peanut-caramel-logo">
              <Link to="/">
                <StaticImage
                  alt="Peanut Caramel logo"
                  src="../images/peanut-caramel-logo-white.svg"
                  placeholder="blurred"
                  width={120}
                  color="white"
                />
              </Link>
            </div>
          </div>

          <hr className="is-hidden-tablet" />

          <div className="column">
            <div className="content has-text-white footer-nav-links">
              <div className="columns is-mobile">
                <div className="column">
                  <ul>
                    <li>
                      <a
                        href="https://grubdailygourmet.myshopify.com"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Shop
                      </a>
                    </li>
                    <li>
                      <Link to="/about">About</Link>
                    </li>
                    <li>
                      <Link to="/recipes">Recipes</Link>
                    </li>
                    <li>
                      <Link to="/stockists">Stockists</Link>
                    </li>
                  </ul>
                </div>

                <div className="column">
                  <ul>
                    <li>
                      <Link to="/privacy-policy">Privacy Policy</Link>
                    </li>

                    <li>
                      <Link to="/contact">Contact</Link>
                    </li>

                    <li>
                      <Link to="/contact/wholesale-enquiry">Wholesale Enquiry</Link>
                    </li>

                    <li>
                      <Link to="/stockist-listing">Stockist Listing</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <hr className="is-hidden-tablet" />

          <div className="column">
            <div className="content">
              <p className="mb-0"><b>Grubdaily Gourmet Ltd</b></p>
              <p className="mb-0">Registered in Scotland No. SC833971</p>
              <p className="mb-0">Nicol Road</p>
              <p className="mb-0">Broxburn, U.K.</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
